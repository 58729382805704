import React from "react";
// @ts-ignore
import thing from "../assets/design.png";
// @ts-ignore
import me from "../assets/memoji.jpg";
// @ts-ignore
import github from "../assets/github.svg";
// @ts-ignore
import instagram from "../assets/instagram.svg";
// @ts-ignore
import spotify from "../assets/spotify.svg";

const Hero = () => {
  return (
    <div className="font-poppins">
      <div className="hidden lg:block">
        <img
          className="absolute top-[-50px] right-5 w-[650px] h-[650px] 2xl:w-[850px] 2xl:h-[850px]"
          src={thing}
          alt="Logo"
          draggable="false"
        />
      </div>
      <div className="flex flex-col w-full lg:max-w-[778px] 2xl:max-w-[900px] py-[76px] px-[86px] space-y-9 text-center lg:text-start gap-5">
        <img
          className="relative mx-auto lg:mx-0 w-[100px] h-[100px] lg:left-[-15px]"
          src={me}
          alt="me"
          draggable="false"
        />
        <p className="text-gray-900 text-xl font-medium lg:text-2xl 2xl:text-3xl">
          Hi, I'm Anees. An 18 year old self taught fullstack software & blockchain
          developer.
        </p>
        <p className="font-light text-lg lg:text-xl 2xl:text-2xl">
          I know various programming languages such
          as Python, GoLang, C++ and TypeScript.
        </p>
        <p className="font-light text-lg lg:text-xl 2xl:text-2xl">I love sneakers, coding, and music.</p>
        <p className="font-light text-lg lg:text-xl 2xl:text-2xl">
          Previously one of the founders of{" "}
          <a
            className="cursor-pointer underline hover:text-slate-600"
            href="https://github.com/Incognito-CLI/IncognitoCLI"
            rel="noreferrer"
            target="_blank"
          >
            Incognito CLI
          </a>
          {" "}and software developer at{" "}
          <a
            className="cursor-pointer underline hover:text-slate-600"
            href="https://breeze.gg/"
            rel="noreferrer"
            target="_blank"
          >
            Breeze
          </a>
          .
        </p>
      </div>
      <div className="fixed flex w-full lg:bottom-0 justify-center my-4 p-4 lg:justify-end">
        {[
          [github, "github", "https://github.com/aalsa16"],
          [
            spotify,
            "spotify",
            "https://open.spotify.com/user/vcvd5ep4dfwls02eiwxa7ye35?si=becbfc4dafed4bac",
          ],
          [instagram, "instagram", "https://www.instagram.com/a.alsa14/"],
        ].map(([src, alt, link]) => (
          <a
            className="cursor-pointer mx-3"
            href={link}
            rel="noreferrer"
            target="_blank"
          >
            <img
              className="w-6 h-6"
              src={src}
              alt={alt}
              draggable="false"
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Hero;
